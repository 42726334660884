import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    scarvesCount: 0,
    countryCount: 0,
    scarvesData: [],
    homeTopScroll: 0,
};

export const dataSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        setScarvesCount: (state, action) => {
            state.scarvesCount = action.payload;
        },
        setCountryCount: (state, action) => {
            state.countryCount = action.payload;
        },
        setScarvesData: (state, action) => {
            state.scarvesData = action.payload;
        },
        setHomeTopScroll: (state, action) => {
            state.homeTopScroll = action.payload;
        },
    },
});

export const {
    setCountryCount,
    setScarvesCount,
    setScarvesData,
    setHomeTopScroll,
} = dataSlice.actions;

export default dataSlice.reducer;
