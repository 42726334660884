import {
    setCountryCount,
    setScarvesCount,
    setScarvesData,
} from '@redux/slices/dataSlice';

const NON_COUNTRIES_COUNT = 4;

export const onGetSuccess = dispatch => data => {
    const countriesCount =
        data.items.length - NON_COUNTRIES_COUNT;
    const scarvesCount = data.items.reduce(
        (acc, category) => acc + category.scarves.length,
        0
    );

    dispatch(setScarvesCount(scarvesCount));
    dispatch(setCountryCount(countriesCount));
    dispatch(setScarvesData(data.items));
};
