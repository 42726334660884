import React, {
    useContext,
    useEffect,
    useState,
} from 'react';
import * as Styled from './styled';
import Flag from '@components/flag';
import { useDispatch, useSelector } from 'react-redux';
import {
    getDetailedIso3,
    getDetailedTitle,
    getScarvesData,
    getDetailedDataItems,
} from '@containers/detailed/selectors';
import { useParams } from 'react-router-dom';
import { setDetailedInfo } from '@containers/detailed/actions';
import Modal from '@material-ui/core/Modal';
import { ThemeContext } from '@src/index';
import SportsImage from '@src/assets/sports.png';
import UltrasImage from '@src/assets/ultras.png';

const BLACK_BG = [
    'https://api.mamikonyan.io/logos/ultras/faf.png',
];
const WIDTH_LOGO = [
    'https://api.mamikonyan.io/logos/germany/union_b.png',
    'https://api.mamikonyan.io/logos/russia/zenit.png',
    'https://api.mamikonyan.io/logos/japan/japan.png',
    'https://api.mamikonyan.io/logos/ultras/proud_eagles.png',
    'https://api.mamikonyan.io/logos/ultras/virage_sud.png',
    'https://api.mamikonyan.io/logos/austria/austria.png',
];

const Detailed = () => {
    const dispatch = useDispatch();

    const { CARD_BACKGROUND_COLOR } =
        useContext(ThemeContext);

    const detailedIso3 = useSelector(getDetailedIso3);
    const detailedTitle = useSelector(getDetailedTitle);
    const categories = useSelector(getScarvesData);
    const detailedScarves = useSelector(
        getDetailedDataItems
    );
    const { categorySlug } = useParams();

    const [isModalOpen, setModalOpen] = useState(false);
    const [scarfPath, setScarfPath] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (!detailedIso3) {
            const singleCategory = categories.find(
                category => category.slug === categorySlug
            );

            if (singleCategory) {
                setDetailedInfo(dispatch, singleCategory);
            }
        }
    }, [detailedIso3, categories, categorySlug, dispatch]);

    if (!detailedIso3) return null;

    return (
        <Styled.DetailedWrapper>
            <Styled.DetailedHeader>
                <Styled.DetailedTitleBox
                    background={CARD_BACKGROUND_COLOR}
                >
                    {detailedIso3 === 'OTHR' ? (
                        <img
                            src={SportsImage}
                            alt="Other"
                            width={28}
                            height={28}
                        />
                    ) : detailedIso3 === 'ULTR' ? (
                        <img
                            src={UltrasImage}
                            alt="Ultras"
                            width={28}
                            height={28}
                        />
                    ) : (
                        <Flag
                            iso3={detailedIso3}
                            size={28}
                        />
                    )}
                    <Styled.DetailedHeaderText>
                        {detailedTitle}
                    </Styled.DetailedHeaderText>
                </Styled.DetailedTitleBox>
            </Styled.DetailedHeader>
            <Styled.DetailedBodyWrapper>
                {detailedScarves.map(
                    (singleScarf, index) => (
                        <Styled.DetailedCardWrapper
                            key={`${singleScarf.name}-${index}`}
                            onClick={() => {
                                setModalOpen(true);
                                setScarfPath(
                                    singleScarf.scarfPath
                                );
                            }}
                        >
                            <Styled.DetailedCardLogoWrapper
                                isBlack={BLACK_BG.includes(
                                    singleScarf.logoPath
                                )}
                            >
                                <Styled.DetailedCardLogo
                                    widthBased={WIDTH_LOGO.includes(
                                        singleScarf.logoPath
                                    )}
                                    src={
                                        singleScarf.logoPath
                                    }
                                />
                            </Styled.DetailedCardLogoWrapper>
                            <Styled.DetailedCardTitle>
                                {singleScarf.name}
                            </Styled.DetailedCardTitle>
                        </Styled.DetailedCardWrapper>
                    )
                )}
            </Styled.DetailedBodyWrapper>

            <Modal
                open={isModalOpen}
                onClose={() => {
                    setModalOpen(false);
                    setScarfPath('');
                }}
            >
                <Styled.DetailedModalWrapper>
                    <img
                        width="100%"
                        height="auto"
                        src={scarfPath}
                        alt=""
                    />
                </Styled.DetailedModalWrapper>
            </Modal>
        </Styled.DetailedWrapper>
    );
};

export default Detailed;
