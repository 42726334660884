import axios from 'axios';

export const getScarvesDataAPI = (onSuccess, onError) => {
    axios
        .get('https://api.mamikonyan.io/scarves/data.json')
        .then(res => {
            onSuccess(res.data);
        })
        .catch(err => {
            onError(err);
        });
};
