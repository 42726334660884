import {
    setDetailedName,
    setDetailedItems,
    setDetailedIso,
} from '@redux/slices/detailedSlice';

export const setDetailedInfo = (dispatch, payload) => {
    dispatch(setDetailedName(payload.categoryName));
    dispatch(setDetailedItems(payload.scarves));
    dispatch(setDetailedIso(payload.iso3));
};
