import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`  
  body {
    margin: 0;
    padding: 0;
    font-family: 'Noto Sans', serif;
    background: ${({ PRIMARY_BG_COLOR }) =>
        PRIMARY_BG_COLOR};
    color: ${({ PRIMARY_FONT_COLOR }) =>
        PRIMARY_FONT_COLOR};
    transition: all 0.4s;
    
    > div:nth-child(6) {
      display: flex;
      align-items: center;
      justify-content: center;
       
      > div {
        outline: none;
      }
    }
  }
`;
