import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    name: null,
    items: [],
    iso3: null,
};

export const detailedSlice = createSlice({
    name: 'detailed',
    initialState,
    reducers: {
        setDetailedName: (state, action) => {
            state.name = action.payload;
        },
        setDetailedItems: (state, action) => {
            state.items = action.payload;
        },
        setDetailedIso: (state, action) => {
            state.iso3 = action.payload;
        },
    },
});

export const {
    setDetailedName,
    setDetailedItems,
    setDetailedIso,
} = detailedSlice.actions;

export default detailedSlice.reducer;
