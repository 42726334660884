import React from 'react';
import * as Styled from './styled';

const Loader = () => {
    return (
        <Styled.LoaderWrapper>
            <Styled.LoaderCircle />
        </Styled.LoaderWrapper>
    );
};

export default Loader;
