import styled from 'styled-components';
import Card from '@components/card';

export const DetailedWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - 124px);
`;

export const DetailedHeader = styled.div`
    display: flex;
    padding: 48px 0;
    align-items: center;
`;

export const DetailedTitleBox = styled.div.attrs(
    ({ background }) => ({
        style: {
            background,
        },
    })
)`
    display: flex;
    align-items: center;
    padding: 10px 40px;
    border-radius: 40px;
    cursor: default;
    user-select: none;
`;

export const DetailedHeaderText = styled.div`
    font-size: 22px;
    margin-left: 12px;
`;

export const DetailedBodyWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    > div {
        width: 300px;
        height: 300px;
        margin: 20px;
    }
`;

export const DetailedCardWrapper = styled(Card)`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const DetailedCardLogoWrapper = styled.div`
    width: 180px;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ isBlack }) =>
        isBlack ? '#000000' : '#ffffff'};
    border-radius: 50%;
`;

export const DetailedCardLogo = styled.img`
    height: ${({ widthBased }) =>
        widthBased ? 'auto' : '120px'};
    width: ${({ widthBased }) =>
        widthBased ? '120px' : 'auto'};
`;

export const DetailedCardTitle = styled.div`
    font-size: 16px;
    margin-top: 24px;
    text-align: center;
`;

export const DetailedModalWrapper = styled.div`
    width: 80vw;
`;
