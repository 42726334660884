import {
    setDetailedName,
    setDetailedItems,
    setDetailedIso,
} from '@redux/slices/detailedSlice';
import { setHomeTopScroll } from '@redux/slices/dataSlice';

export const setDetailedInfo = (dispatch, payload) => {
    dispatch(setDetailedName(payload.categoryName));
    dispatch(setDetailedItems(payload.scarves));
    dispatch(setDetailedIso(payload.iso3));
};

export const setTopScroll = (dispatch, topScroll) => {
    dispatch(setHomeTopScroll(topScroll));
};
