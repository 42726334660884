export const getDetailedIso3 = state =>
    state.detailedData.iso3;

export const getDetailedTitle = state =>
    state.detailedData.name;

export const getScarvesData = state =>
    state.globalData.scarvesData;

export const getDetailedDataItems = state =>
    state.detailedData.items;
