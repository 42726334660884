import React, { useContext } from 'react';
import * as Styled from './styled';
import { ThemeContext } from '@src/index';

const Card = ({
    children,
    className,
    onClick = () => {},
}) => {
    const { CARD_BACKGROUND_COLOR } =
        useContext(ThemeContext);

    return (
        <Styled.CardWrapper
            className={className}
            onClick={onClick}
            bgColor={CARD_BACKGROUND_COLOR}
        >
            {children}
        </Styled.CardWrapper>
    );
};

export default Card;
