import React from 'react';
import * as Styled from './styled';
import { useSelector } from 'react-redux';
import {
    getScarvesCount,
    getCountryCount,
} from '@containers/home/header/selectors';

const Header = () => {
    const scarvesCount = useSelector(getScarvesCount);
    const countryCount = useSelector(getCountryCount);

    return (
        <Styled.HeaderWrapper>
            Greetings! I'm Paruyr, hailing from Yerevan,
            Armenia 🇦🇲
            <br />
            In 2007, I embarked on my football scarves
            collection journey, and it has been an
            exhilarating ride ever since. Currently, my
            collection boasts an impressive count of{' '}
            <strong>{scarvesCount}</strong> scarves sourced
            from <strong>{countryCount}</strong> different
            countries 🌍🍻
            <br />
            If you're keen on contributing to my
            ever-growing assortment, please feel free to
            reach out to me via{' '}
            <Styled.HeaderFBLink
                target="_blank"
                href="https://www.facebook.com/mam1konyan"
            >
                Facebook
            </Styled.HeaderFBLink>{' '}
            or{' '}
            <Styled.HeaderIGLink
                target="_blank"
                href="https://www.instagram.com/mam1konyan/"
            >
                Instagram
            </Styled.HeaderIGLink>{' '}
            . I'm always excited to connect with fellow
            enthusiasts! 📥
        </Styled.HeaderWrapper>
    );
};

export default Header;
