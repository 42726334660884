import styled from 'styled-components';

export const HeaderWrapper = styled.div`
    max-width: 800px;
    margin: 30px auto;
    padding: 0 12px;
    font-size: 16px;
    line-height: 2.2;

    @media (min-width: 480px) {
        font-size: 18px;
        padding: 0 18px;
    }
    @media (min-width: 768px) {
        font-size: 24px;
        padding: 0 30px;
    }
`;

export const HeaderFBLink = styled.a`
    text-decoration: none;
    cursor: pointer;
    color: #4267b2;
`;

export const HeaderIGLink = styled.a`
    text-decoration: none;
    cursor: pointer;
    color: #e1306c;
`;
