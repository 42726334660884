import {
    setDetailedName,
    setDetailedItems,
    setDetailedIso,
} from '@redux/slices/detailedSlice';

export const setDetailedInfo = dispatch => {
    dispatch(setDetailedName(null));
    dispatch(setDetailedItems([]));
    dispatch(setDetailedIso(null));
};
