import styled, { css } from 'styled-components';

const SliderMode = ({ isDarkMode }) => {
    if (isDarkMode) {
        return css`
            background-color: #2e86de;
            &:before {
                transform: translateX(26px) scale(0.6);
                height: 20px;
                width: 20px;
                background-color: white;
                bottom: 6.5px;
                left: 5px;
                box-shadow: 14px -10px 0px -7px lightgrey,
                    -1px -15px 0px -8px lightgrey,
                    12px 13px 0px -6px lightgrey,
                    5px -1px 5px 13px white,
                    5px -1px 0px 12px white;
            }
        `;
    }
    return css`
        &:before {
            height: 23px;
            width: 23px;
            left: 6px;
            bottom: 6px;
            background-color: yellow;
            box-shadow: 0px 0px 5px 4px rgb(255, 217, 0);
        }
    `;
};

export const ThemeSwitchWrapper = styled.label`
    margin-left: auto;
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
`;

export const ThemeSwitchInput = styled.input`
    opacity: 0;
    width: 0;
    height: 0;
`;

export const ThemeSwitchSlider = styled.span`
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: lightblue;
    transition: 0.4s ease-in-out;
    border-radius: 34px;
    ${SliderMode};
    &:before {
        border-radius: 50%;
        position: absolute;
        content: '';
        transition: 0.4s;
    }
`;
