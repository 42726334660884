import { configureStore } from '@reduxjs/toolkit';
import dataReducer from '@redux/slices/dataSlice';
import detailedReducer from '@redux/slices/detailedSlice';

export const store = configureStore({
    reducer: {
        globalData: dataReducer,
        detailedData: detailedReducer,
    },
});
