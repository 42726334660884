import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const FooterWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 62px;
    padding: 16px 20px;
    background: #2d333b;
    box-sizing: border-box;
    color: #adbac7;
`;

export const FooterIcon = styled(FontAwesomeIcon)`
    margin: 0 4px;
`;

export const FooterLink = styled.a`
    margin: -2px 4px 0;
    color: #adbac7;
    text-decoration: none;
    &:hover {
        color: #2980b9;
    }
`;
