import React from 'react';
import * as Styled from './styled';
import FLAGS from './flags';

const Flag = ({ size, iso3 }) => {
    return (
        <Styled.FlagWrapper size={size}>
            {FLAGS[iso3]}
        </Styled.FlagWrapper>
    );
};

export default Flag;
