import React, { useEffect } from 'react';
import Header from '@containers/home/header';
import Body from '@containers/home/body';
import { useSelector } from 'react-redux';
import { getHomeScroll } from '@containers/home/selectors';

const Home = () => {
    const homeTopScroll = useSelector(getHomeScroll);

    useEffect(() => {
        window.scrollTo(0, homeTopScroll);
    }, [homeTopScroll]);

    return (
        <div>
            <Header />
            <Body />
        </div>
    );
};

export default Home;
