import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import GlobalStyles from './globalStyles';
import App from '@containers/app';
import { store } from './reduxStore';
import { Provider } from 'react-redux';
import { inject } from '@vercel/analytics';

const DARK_COLOR_PALETTE = {
    PRIMARY_BG_COLOR: '#22272e',
    PRIMARY_FONT_COLOR: '#adbac7',
    CARD_BACKGROUND_COLOR: '#373e47',
};

const LIGHT_COLOR_PALETTE = {
    PRIMARY_BG_COLOR: '#f1f2f6',
    PRIMARY_FONT_COLOR: '#24292f',
    CARD_BACKGROUND_COLOR: '#ced6e0',
};

export const ThemeContext = React.createContext({});

const Root = () => {
    const [isDarkMode, setDarkMode] = useState(true);
    const [palette, setPalette] = useState(
        DARK_COLOR_PALETTE
    );

    useEffect(() => {
        if (isDarkMode) {
            setPalette(DARK_COLOR_PALETTE);
        } else {
            setPalette(LIGHT_COLOR_PALETTE);
        }
    }, [isDarkMode]);

    return (
        <React.StrictMode>
            <Provider store={store}>
                <GlobalStyles {...palette} />
                <ThemeContext.Provider
                    value={{
                        ...palette,
                        setDarkMode,
                        isDarkMode,
                    }}
                >
                    <App />
                </ThemeContext.Provider>
            </Provider>
        </React.StrictMode>
    );
};

ReactDOM.render(<Root />, document.getElementById('root'));
inject();
