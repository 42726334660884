import React, { useContext, useMemo } from 'react';
import * as Styled from './styled';
import { useDispatch, useSelector } from 'react-redux';
import { getScarvesData } from '@containers/home/body/selectors';
import Flag from '@components/flag';
import {
    setDetailedInfo,
    setTopScroll,
} from '@containers/home/body/actions';
import { ThemeContext } from '@src/index';
import SportsImage from '../../../assets/sports.png';
import UltrasImage from '../../../assets/ultras.png';

const Body = () => {
    const dispatch = useDispatch();
    const { PRIMARY_FONT_COLOR } = useContext(ThemeContext);

    const categoriesArray = useSelector(getScarvesData);

    const scarvesCategories = useMemo(
        () =>
            categoriesArray.map(singleCategory => (
                <Styled.HomeCardWrapper
                    key={singleCategory.iso3}
                >
                    <Styled.HomeCardLink
                        to={`/${singleCategory.slug}`}
                        onClick={() => {
                            const topScroll =
                                window.scrollY;

                            setTopScroll(
                                dispatch,
                                topScroll
                            );
                            setDetailedInfo(
                                dispatch,
                                singleCategory
                            );
                        }}
                        color={PRIMARY_FONT_COLOR}
                    >
                        {singleCategory.iso3 === 'OTHR' ? (
                            <img
                                src={SportsImage}
                                alt="Other"
                                width={120}
                                height={120}
                            />
                        ) : singleCategory.iso3 ===
                          'ULTR' ? (
                            <img
                                src={UltrasImage}
                                alt="Ultras"
                                width={120}
                                height={120}
                            />
                        ) : (
                            <Flag
                                iso3={singleCategory.iso3}
                                size={120}
                            />
                        )}
                        <Styled.HomeCardTitle>
                            {`${singleCategory.categoryName} (${singleCategory.scarves.length})`}
                        </Styled.HomeCardTitle>
                    </Styled.HomeCardLink>
                </Styled.HomeCardWrapper>
            )),
        [categoriesArray, dispatch, PRIMARY_FONT_COLOR]
    );

    return (
        <Styled.HomeBody>
            {scarvesCategories}
        </Styled.HomeBody>
    );
};

export default Body;
