import styled from 'styled-components';
import Card from '@components/card';
import { Link } from 'react-router-dom';

export const HomeBody = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    > div {
        width: 320px;
        height: 320px;
        margin: 20px;
        @media (min-width: 480px) {
            width: 250px;
            height: 250px;
        }
    }
`;

export const HomeCardWrapper = styled(Card)`
    display: flex;
`;

export const HomeCardTitle = styled.div`
    margin-top: 24px;
    font-size: 24px;
`;

export const HomeCardLink = styled(Link).attrs(
    ({ color }) => ({
        style: {
            color,
        },
    })
)`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-decoration: none;
`;
