import styled, { keyframes } from 'styled-components';

const loaderG = keyframes`
  0%{background-color: rgba(255, 255, 255, .2);}
  25%{background-color: rgba(255, 255, 255, 1);}
  50%{background-color: rgba(255, 255, 255, .2);}
  75%{background-color: rgba(255, 255, 255, .2);}
  100%{background-color: rgba(255, 255, 255, .2);}
`;

const loaderM = keyframes`0%{background-color: rgba(255, 255, 255, .2);}
  25%{background-color: rgba(255, 255, 255, .2);}
  50%{background-color: rgba(255, 255, 255, 1);}
  75%{background-color: rgba(255, 255, 255, .2);}
  100%{background-color: rgba(255, 255, 255, .2);}`;

const loaderD = keyframes`
  0%{background-color: rgba(255, 255, 255, .2);}
  25%{background-color: rgba(255, 255, 255, .2);}
  50%{background-color: rgba(255, 255, 255, .2);}
  75%{background-color: rgba(255, 255, 255, 1);}
  100%{background-color: rgba(255, 255, 255, .2);}`;

export const LoaderWrapper = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 11;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2c3e50;
`;

export const LoaderCircle = styled.div`
    position: relative;
    width: 12px;
    height: 12px;
    border-radius: 12px;
    animation: ${loaderM} 1.5s ease-in-out infinite;

    &:before {
        content: '';
        position: absolute;
        top: 0px;
        left: -25px;
        height: 12px;
        width: 12px;
        border-radius: 12px;
        animation: ${loaderG} 1.5s ease-in-out infinite;
    }

    :after {
        content: '';
        position: absolute;
        top: 0px;
        left: 25px;
        height: 10px;
        width: 10px;
        border-radius: 10px;
        animation: ${loaderD} 1.5s ease-in-out infinite;
    }
`;
