import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 62px;
    padding: 16px 32px;
    background: #2d333b;
    box-sizing: border-box;
`;

export const HeaderText = styled.div.attrs(
    ({ marginLeft }) => ({
        style: {
            marginLeft,
        },
    })
)`
    font-family: 'Krona One', sans-serif;
    color: #cdd9e5;
    cursor: default;
    user-select: none;
    font-size: 12px;

    @media (min-width: 480px) {
        font-size: 18px;
    }
`;

export const HeaderBackArrow = styled(Link)`
    text-decoration: none;
    color: #cdd9e5;
`;
