import React from 'react';
import * as Styled from './styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { setDetailedInfo } from '@containers/header/actions';
import { useParams } from 'react-router-dom';
import ThemeSwitch from '@containers/header/theme-switch';

const Header = () => {
    const dispatch = useDispatch();
    const { categorySlug } = useParams();

    const isHomePage = !categorySlug;

    return (
        <Styled.HeaderWrapper>
            {!isHomePage && (
                <Styled.HeaderBackArrow
                    to="/"
                    onClick={() =>
                        setDetailedInfo(dispatch)
                    }
                >
                    <FontAwesomeIcon
                        icon={faArrowLeft}
                        size="2x"
                        cursor="pointer"
                    />
                </Styled.HeaderBackArrow>
            )}
            <Styled.HeaderText
                marginLeft={isHomePage ? 0 : 36}
            >
                scarves.mamikonyan.io
            </Styled.HeaderText>
            <ThemeSwitch />
        </Styled.HeaderWrapper>
    );
};

export default Header;
