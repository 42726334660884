import React from 'react';
import * as Styled from './styled';
import {
    faCode,
    faHeart,
    faGlobe,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Footer = () => {
    return (
        <Styled.FooterWrapper>
            <Styled.FooterIcon
                icon={faCode}
                color="#2980b9"
                title="Created"
            />
            {'  '} with{'  '}
            <Styled.FooterIcon
                icon={faHeart}
                color="#c0392b"
                title="love"
            />
            {'  '} by{'  '}
            <Styled.FooterLink
                href="http://mamikonyan.io/"
                target="_blank"
            >
                Paruyr Mamikonyan{' '}
                <sup>
                    <FontAwesomeIcon icon={faGlobe} />
                </sup>
            </Styled.FooterLink>
        </Styled.FooterWrapper>
    );
};

export default Footer;
