import React, { useEffect, useState } from 'react';
import {
    Switch,
    Route,
    BrowserRouter as Router,
} from 'react-router-dom';
import Home from '@containers/home';
import Detailed from '@containers/detailed';
import { getScarvesDataAPI } from '@api/getScarvesData';
import { useDispatch } from 'react-redux';
import { onGetSuccess } from '@containers/app/actions';
import Header from '@containers/header';
import Footer from '@containers/footer';
import Loader from '@components/loader';

const App = () => {
    const dispatch = useDispatch();

    const [isLoader, setLoader] = useState(true);

    useEffect(() => {
        getScarvesDataAPI(
            onGetSuccess(dispatch),
            console.log
        );
    }, [dispatch]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoader(false);
        }, 750);
        return () => clearTimeout(timeout);
    }, []);

    return (
        <Router>
            <Switch>
                <Route path="/:categorySlug">
                    <Header />
                    <Detailed />
                </Route>
                <Route path="/">
                    {isLoader ? (
                        <Loader />
                    ) : (
                        <>
                            <Header />
                            <Home />
                        </>
                    )}
                </Route>
            </Switch>
            <Footer />
        </Router>
    );
};

export default App;
