import React, { useContext } from 'react';
import * as Styled from './styled';
import { ThemeContext } from '@src/index';

const ThemeSwitch = () => {
    const { setDarkMode, isDarkMode } =
        useContext(ThemeContext);

    return (
        <Styled.ThemeSwitchWrapper>
            <Styled.ThemeSwitchInput
                type="checkbox"
                onChange={() => setDarkMode(!isDarkMode)}
            />
            <Styled.ThemeSwitchSlider
                isDarkMode={isDarkMode}
            />
        </Styled.ThemeSwitchWrapper>
    );
};

export default ThemeSwitch;
